.offerbox {
    margin: 3px;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

.collapsed .offerpicture {
    width: 20vw;
    cursor: pointer;
}

.collapsed .offerpicture img{
    max-width: 20vw;
    max-height: 180px;
}

.expanded .offerpicture {
    max-width: 30vw;
    cursor: pointer;
    
}

.expanded  img{
    max-width: 30vw;
}

.offerdescription {
    flex: 1;
    margin-left: 10px;
    text-align: left;
    cursor: pointer;
}

.offerdescription .offertitle {
    font-size: 1.4em;
    font-weight: bold;
}

.offerdescription .offerprice {
    font-size: 1.2em;
    font-weight: bold;
    color: rgb(105, 15, 15);
}

.offerdescription .offerothers span {
    font-size: 0.8em;
    display: inline-block;
    margin: 0 10px 0 0;
    color: rgb(75, 80, 109);
}

.offerdescription .offershort {
    color: rgb(29, 25, 25);
}

span.oneprop {
    display: inline-block;
    border: 1px solid gray;
    margin: 2px;
    padding: 1px 3px;
    font-size: 0.9em;
    color: rgb(55, 45, 71);
    background-color: rgb(207, 207, 228);
}

span.oneprop .val {
    font-weight: bold;
    
}

.settingsPanel {
    display: flex;
    flex-direction: row;
}

.collections > div{
    display: block;
}

@media only screen and (max-width: 700px) {
    .offerbox {
        margin: 3px;
        padding: 5px;
        border: 1px solid gray;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
    }
    
    .collapsed .offerpicture {
        width: 90vw;
    }
    
    .collapsed .offerpicture img{
        max-width: 90vw;
        max-height: 130px;
    }
    
    .expanded .offerpicture {
        max-width: 9vw;
    }
    
    .expanded  img{
        max-width: 90vw;
    }

    .settingsPanel {
        display: flex;
        flex-direction: column;
    }

    .collections{
        text-align: left;
    }

    .collections > div{
        display: inline-block;
    }
  }

  .loadingScreen {
      background-color: rgba(25, 44, 71, 0.74);
      position: absolute;
      z-index: 100;
      width:100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .locationBubble {
      display:inline-block;
      font-size: 10px;
      padding: 2px;
      border: 1px solid gray;
      margin: 2px;
      max-width: 60px;
      color: black;
      line-height: 1.1;
  }